<template>
  <div class="mt-12">
     <div class="card bg-white rounded-xl p-4 ">
      <div class="relative">
      <div class="card top_primary_card bg-success font-bold text-white p-4 rounded-xl absolute -top-11 w-full">
        Lead list ({{totalLeadCount}})
      </div>
      </div>
      <div class="mt-8">
        <div class="flex flex-wrap mb-4">
          <div class="" v-for="(status, index) in statusList" :key="index" v-show="status.statusName !== 'Won'">
              <StatusBtn class=" whitespace-nowrap" @buttonAction="toggleActiveAndInactive(index)" :selected="status.selected" :btnText="status.statusName" :lastInlist="statusList.length === index + 1"/>     
          </div>
        </div>
        <div class="flex flex-wrap justify-between items-center">
          <div class="w-72 mb-4">
            <SearchTextInput @searchCall="searchCall" :placeholderText="`Search Lead...`"/>
          </div>
          <div class="mb-4">
            <Button @buttonAction="addNewCust(false)" :btnSize="'large'" :textColor="'white'" :btnColor="'success'" :btnText="'Add New'"/>
          </div>
        </div>
      </div>
      <div>
        <div style="overflow-y: auto; white-space: nowrap;" class="">
          <div class="text-gray4  min-w-min border-b border-gray1 font-bold flex heading-7  px-3 py-3 items-center">
            <div class="lead_name_col uppercase  flex items-center" :class="titleObj[0].icon !== '' ? 'text-primary' : ''">
              <span class="cursor-pointer" @click="sortByColumn(0)">{{titleObj[0].title}}</span>
               <div v-if="titleObj[0].icon !== ''">
                  <descIconSvg v-if="titleObj[0].icon === 'DESC'" class="ml-2" :width="'20px'" :height="'20px'" />
                  <ascIconSvg v-else class="ml-2" :width="'20px'" :height="'20px'" />
                </div>
            </div>
            <div class="lead_name_col uppercase  flex items-center" :class="titleObj[1].icon !== '' ? 'text-primary' : ''">
              <span class="cursor-pointer" @click="sortByColumn(1)">{{titleObj[1].title}}</span>
               <div v-if="titleObj[1].icon !== ''">
                  <descIconSvg v-if="titleObj[1].icon === 'DESC'" class="ml-2" :width="'20px'" :height="'20px'" />
                  <ascIconSvg v-else class="ml-2" :width="'20px'" :height="'20px'" />
                </div>
            </div>
            <div class="lead_name_col uppercase  flex items-center" :class="titleObj[2].icon !== '' ? 'text-primary' : ''">
              <span class="cursor-pointer" @click="sortByColumn(2)">{{titleObj[2].title}}</span>
               <div v-if="titleObj[2].icon !== ''">
                  <descIconSvg v-if="titleObj[2].icon === 'DESC'" class="ml-2" :width="'20px'" :height="'20px'" />
                  <ascIconSvg v-else class="ml-2" :width="'20px'" :height="'20px'" />
                </div>
            </div>
            <div class="lead_name_col uppercase  flex items-center" :class="titleObj[3].icon !== '' ? 'text-primary' : ''">
              <span class="cursor-pointer"  @click="sortByColumn(3)">{{titleObj[3].title}}</span>
               <div v-if="titleObj[3].icon !== ''">
                  <descIconSvg v-if="titleObj[3].icon === 'DESC'" class="ml-2" :width="'20px'" :height="'20px'" />
                  <ascIconSvg v-else class="ml-2" :width="'20px'" :height="'20px'" />
                </div>
            </div>
            <div class="lead_name_col uppercase  flex items-center" :class="titleObj[4].icon !== '' ? 'text-primary' : ''">
              <span class="cursor-pointer" @click="sortByColumn(4)">{{titleObj[4].title}}</span>
               <div v-if="titleObj[4].icon !== ''">
                  <descIconSvg v-if="titleObj[4].icon === 'DESC'" class="ml-2" :width="'20px'" :height="'20px'" />
                  <ascIconSvg v-else class="ml-2" :width="'20px'" :height="'20px'" />
                </div>
            </div>
          </div>
          <div v-if="leadList.length > 0">
            <div v-for="(data, index) in leadList" :key="index"  class="min-w-min flex heading-5 border-b border-gray1  bg-white  text-sm px-3 py-3 items-center hover:bg-gray1 cursor-pointer" @click="openLeadDetails (data.customerId)">
              <div class="lead_name_col  ">
                <div class="">
                <!-- <p class="  text-text1 font-bold">{{}}</p> -->
                <p class="  text-text1 font-bold">{{data.companyName}}</p>
                <p class="  text-gray4 text">{{data.firstName}} {{data.lastName}}</p>
                </div>
              </div>
              <div class="lead_name_col  ">
                <div class="flex items-center">
                <span class="  text-gray4 heading-6">{{data.emailAddress === '' ? '-' : data.emailAddress}}</span>
                </div>
              </div>
              <div class="lead_name_col">
                <div class="flex items-center">
                <span class="  text-gray4 heading-6">{{data.phoneNumber === '' ? '-' : data.phoneNumber}}</span>
                </div>
              </div>
              <div class="lead_name_col  ">
                <div class="flex items-center">
                <span class="  text-gray4 heading-6">{{data.addedDate | formatDataeTime}}</span>
                </div>
              </div>
              <div class="lead_name_col w-44 ">
                <div class="flex items-center">
                <span :style="`color: ${data.statusColor};`" class=" text-gray4 heading-6">{{data.statusName}}</span>
                </div>
              </div>
            </div>
          </div>
          <div v-else>
            <NoRecords :alertMessage="`No records found.`" />
          </div>
          <div>
          <Pagination
            :totalDataCount="totalLeadCount"
            :resetPagination="resetPaginationValues"
            @callApiAsPerPagination="getLeadDataFromPagination"/>
        </div>
        </div>
      </div>
     </div>
     <AddLead v-if="addLead" />
   </div>
</template>
<script>
import AddLead from '@/View/lead/components/addUpdateLead.vue'
import MyJobApp from '@/api/MyJobApp.js'
import SearchTextInput from '@/View/components/searchTextInput.vue'
import Button from '@/View/components/globalButton.vue'
import ascIconSvg from '@/assets/images/svg/ascIconSvg.vue'
import deboucneMixin from '@/mixins/debounce.js'
import descIconSvg from '@/assets/images/svg/descIconSvg.vue'
import NoRecords from '@/View/components/noRecords.vue'
import Pagination from '@/View/components/pagination.vue'
import StatusBtn from "@/View/components/statu-filter-btn.vue";
export default {
  name: "lead",
  components: {
    AddLead,
    StatusBtn,
    Pagination,
    NoRecords,
    descIconSvg,
    ascIconSvg,
    SearchTextInput,
    Button,
  },
  mixins: [deboucneMixin],
  data() {
    return {
      titleObj: [
        {title: 'Company Name', icon: '', sortName: 'companyName'},
        {title: 'Email', icon: '', sortName: 'emailAddress'},
        {title: 'Contact', icon: '', sortName: 'phoneNumber'},
        {title: 'Added Date', icon: 'DESC', sortName: 'addedDate'},
        {title: 'Status', icon: '', sortName: 'statusName'}
      ],
      statusList: [],
      leadList: [],
      searchForLead: '',
      getLeadListDebounce: null,
      resetPaginationValues: false,
      addLead: false,
      totalLeadCount: 0,
      filterObject: {
        start: 0,
        length: 20,
        sortOrder: 'DESC',
        isActive: true,
        sortBy: 'addedDate',
        statusList: []
      },
    };
  },
  created() {},
  mounted() {
    this.getLeadStatusList()
    this.getLeadList()
     this.getLeadListDebounce = this.debounce(function () {
      this.resetPaginationValues = true
    }, 500)
    this.$root.$on("addleadHandler", (response) => {
      console.log(response);
      if (response) {
        this.getLeadList()
      }
      this.addLead = false
    });
  },
  beforeDestroy() {
    this.$root.$off('addleadHandler')
  },
  methods: {
    addNewCust () {
      this.addLead= true
    },
    getLeadDataFromPagination (data) {
      this.filterObject.start = data.offset
      this.filterObject.length = data.limit
      
      this.getLeadList(true)
    },
    sortByColumn (index) {
      console.log(index);
      for (let i = 0; i < this.titleObj.length; i++) {
        if (i === index) {
          if (this.titleObj[i].icon === '') {
            this.titleObj[i].icon = 'DESC'
            this.filterObject.sortOrder = 'DESC'
          } else if (this.titleObj[i].icon === 'DESC') {
            this.titleObj[i].icon = 'ASC'
            this.filterObject.sortOrder = 'ASC'
          } else {
            this.titleObj[i].icon = 'DESC'
            this.filterObject.sortOrder = 'DESC'
          }
        } else {
          this.titleObj[i].icon = ''
        }
      }
      this.filterObject.sortBy = this.titleObj[index].sortName
      this.getLeadList(true)
    },
    openLeadDetails (id) {
      this.$router.push({name: 'LeadDetail', params: {leadId: id}})
    },
    searchCall (data) {
      this.searchForLead = data
      this.getLeadListDebounce()
    },
    toggleActiveAndInactive (index) {
      let filter = []
      if (this.statusList[index].statusName.toLowerCase() === 'all') {
        this.statusList[0].selected = true
        this.filterObject.statusList = []
        for (let index = 0; index < this.statusList.length; index++) {
          this.statusList[index].selected = true
        }
      } else {
        this.statusList[index].selected = !this.statusList[index].selected
        this.filterObject.statusList = []
        this.statusList.forEach((a, id) => {
          if (this.statusList[0].selected) {
            if (id !== index && id !== 0) {
              a.selected = false
            } else {
              if (id !== 0) {
                a.selected = true
                this.filterObject.statusList.push(a.leadStatusId)
              }
            }
          } else {
            if (a.selected) {
              filter.push(a)
              this.filterObject.statusList.push(a.leadStatusId)
            }
          }
        })
        this.statusList[0].selected = false
      }
      if (filter.length === this.statusList.length - 1) {
        this.statusList[0].selected = true
        this.filterObject.statusList = []
        for (let index = 0; index < this.statusList.length; index++) {
          this.statusList[index].selected = true
        }
      }
      let arry = this.statusList.filter(a => {
        return a.selected === false
      })
      if (arry.length === this.statusList.length) {
        this.statusList[0].selected = true
        this.filterObject.statusList = []
        for (let index = 0; index < this.statusList.length; index++) {
          this.statusList[index].selected = true
        }
      }
      this.resetPaginationValues = true
      this.getLeadList()
    },
    // toggleActiveAndInactive (index) {
    //   this.statusList[index].selected = !this.statusList[index].selected
    //   this.getLeadList()
    // },
    getLeadStatusList () {
      MyJobApp.getLeadStatusList(
        response => {
          let temp = response.Data
          for (let index = 0; index < temp.length; index++) {
            temp[index].selected = true
          }
          const newFirstElement = {
            statusName: "All",
            selected: true
          }
          this.statusList = [newFirstElement].concat(temp)
          this.$store.dispatch('SetLoader', {status: false, message: ''})
        },
        () => {
          this.resetPaginationValues = false
          this.$store.dispatch('SetLoader', {status: false, message: ''})
        }
      )
    },
    getLeadList (showlistLoader) {
      if (showlistLoader && this.searchForLead !== '') {
        this.$store.dispatch('SetLoader', {status: true, message: 'Loading...'})
      }
      if (this.searchForLead === '') {
        this.$store.dispatch('SetLoader', {status: true, message: 'Loading...'})
      }
    //   this.filterObject.statusList = []
    //   this.statusList.map((data) => {
    //   if (data.selected) {
    //     this.filterObject.statusList.push(data.leadStatusId)
    //   }
    // })
      MyJobApp.getLeadlist(
         this.filterObject.start,
          this.filterObject.length,
          this.searchForLead,
          this.filterObject.sortBy,
          this.filterObject.sortOrder,
          this.filterObject.statusList,
          response => {
            this.totalLeadCount = response.Data.count
            this.leadList = response.Data.tableRow !== null ? response.Data.tableRow : []
            this.resetPaginationValues = false
            this.$store.dispatch('SetLoader', {status: false, message: ''})
          },
          () => {
            this.resetPaginationValues = false
            this.$store.dispatch('SetLoader', {status: false, message: ''})
          }
        )
    }
  }
};
</script>
<style scoped>
.lead_name_col {
  min-width: 280px !important;
  width: 280px !important;
  overflow-wrap: anywhere;
  white-space: pre-wrap;
  padding-right: 8px;
}
</style>