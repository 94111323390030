<template>
  <div class="popup_overlay px-4">
  <div class="custom_dialog rounded-xl" style="width: 800px; overflow: auto; max-height: 85%;">
    <div class="relative">
      <div>
        <div class="bg-success flex justify-between p-3">
          <div class=" font-medium text-white heading-3">
            {{leadDetail.leadId > 0 ? 'Update Lead' : 'Add Lead'}}
          </div>
        </div>
        <div class="p-4">
          <div class="sm:grid grid-cols-12 gap-3">
            <div class="col-span-6 mb-4 sm:mb-0">
              <TextField
                :inputId="'cusfirstnameid2'"
                :textMaxlength="50"
                :showcharLimit="true"
                :inputext="leadDetail.firstName"
                :fieldError="errObj.firstNameErr !== ''"
                :placholderText="`First Name`"
                :lableText="'First Name'"
                :autoFocus="true"
                @keyPressAction="errObj.firstNameErr = ''"
                @inputChangeAction="(data) => leadDetail.firstName = data"  />
                <p class="text-error heading-7">{{errObj.firstNameErr}}</p>
             </div>
            <div class="col-span-6 mb-4 sm:mb-0">
              <TextField
                :inputId="'cuslastnameid'"
                :textMaxlength="50"
                :showcharLimit="true"
                :inputext="leadDetail.lastName"
                :placholderText="`Last Name`"
                :lableText="'Last Name'"
                :autoFocus="false"
                @inputChangeAction="(data) => leadDetail.lastName = data"  />
             </div>
            <div class="col-span-6 mb-4 sm:mb-0">
              <NumberInput
              :showcharLimit="true"
              :inputId="'custprimnumber'"
              :inputext="leadDetail.phoneNumber"
              :textMaxlength="12"
              :placholderText="`Primary Number`"
              :lableText="'Primary Number'"
              :inputType="'text'"
              :autoFocus="false"
              :fieldError="errObj.primNumErr !== '' || numberExist1"
              :setReadOnly="false"
              @keyPressAction="errObj.primNumErr = ''; numberExist1 = false"
              @blurAction="checkContactNumber('prime')"
              @inputChangeAction="(data) => leadDetail.phoneNumber = data"  />
                <p class="text-error heading-7">{{errObj.primNumErr}}</p>
                <div v-if="numberExist1">
                    <p v-if="numberExist1" class="text-error">Number already in use, please use another</p>
                    <span v-for="(data, index) in existArr1" :key="index">
                      <li class="heading-6 text-primary cursor-pointer" @click="routingTodata(data)">{{ data.firstName }} {{ data.firstName }}({{ data.isLead ? 'Lead' : 'Customer'}})</li>
                    </span>
                  </div>
             </div>
            <div class="col-span-6 mb-4 sm:mb-0">
              <TextField
                  :inputId="'customeremail'"
                  :inputext="leadDetail.emailAddress"
                  :placholderText="`Email Address`"
                  :showcharLimit="true"
                  :textMaxlength="250"
                  :lableText="'Email Address'"
                  :inputType="'text'"
                  @blurAction="checkEmail"
                  :autoFocus="false"
                  :setReadOnly="false"
                  @keyPressAction="errObj.emailErr = '';emailExist = false"
                  :fieldError="errObj.emailErr !== '' || emailExist"
                  @inputChangeAction="(data) => leadDetail.emailAddress = data"  />
                <p class="text-error heading-7">{{errObj.emailErr}}</p>
                <div v-if="emailExist">
                    <p v-if="emailExist" class="text-error">Email already in use, please use another</p>
                    <span v-for="(data, index) in existArr3" :key="index">
                      <li class="heading-6 text-primary cursor-pointer" @click="routingTodata(data)">{{ data.firstName }} {{ data.firstName }}({{ data.isLead ? 'Lead' : 'Customer'}})</li>
                    </span>
                  </div>
             </div>
          </div>
        </div>
      </div>
        <div class=" sticky bottom-0 bg-white w-full flex justify-end pr-3">
          <div class="text-rigth flex gap-2 mb-3">
              <Button :btnSize="'medium'" :textColor="'text1'" :btnColor="'gray1'" :btnText="'Discard'" @buttonAction="discard()"/>
              <Button :btnSize="'medium'" :textColor="'white'" :btnColor="'success'" :btnText="`${cusrDetailId > 0 ? 'Update' : 'Save'}`" @buttonAction="saveContactDetail()"/>
          </div>
          </div>
      </div>
  </div>
</div>
</template>
<script>
import Button from '@/View/components/globalButton.vue'
import TextField from '@/View/components/textfield.vue'
import NumberInput from '@/View/components/number-text-field.vue'
import { validateEmail, checkSpeialChar } from "@/utils/validation.js";
import MyJobApp from '@/api/MyJobApp.js'
export default {
name: 'sendEmail',
components: {
  Button,
  NumberInput,
  TextField
},
data () {
  return {
    emailExist: false,
    numberExist1: false,
    existArr1: [],
    existArr3: [],
    cusrDetailId : 0,
    leadDetail: {
      leadId: 0,
      firstName: "",
      lastName: "",
      companyName: "",
      phoneNumber: "",
      otherNumber: "",
      emailAddress: "",
      hotNotes: "",
      jobDetail: {
        jobTitle: '',
        scheduleStartDateTime: '',
        scheduleEndDateTime: '',
        assigneTo: [
          {
            employeeId: 0,
            employeeFirstName: '',
            employeeLastName: ''
          }
        ]
      }
    },
    errObj: {
      firstNameErr: '',
      primNumErr: '',
      emailErr: '',
      jobTitleErr: '',
    }
  }
},
props: ["updatedLeadetail"],
 created() {
},
mounted () {
  if (this.updatedLeadetail !== null && this.updatedLeadetail !== undefined ) {
      this.leadDetail.leadId = this.updatedLeadetail.leadId
      this.leadDetail.firstName = this.updatedLeadetail.firstName
      this.leadDetail.lastName = this.updatedLeadetail.lastName
      this.leadDetail.phoneNumber = this.updatedLeadetail.phoneNumber
      this.leadDetail.otherNumber = this.updatedLeadetail.otherNumber
      this.leadDetail.emailAddress = this.updatedLeadetail.emailAddress
  }
  document.body.style = 'overflow: hidden;'
  this.leadDetail.leadId = parseInt(this.$route.params.leadId)
},
watch: {},
methods: {
  saveContactDetail () {
    let isValid = true
    if (this.leadDetail.firstName.trim() === '') {
      isValid = false
      this.errObj.firstNameErr = 'First name is required'
    }
    if (this.errObj.primNumErr !== '' || this.errObj.emailErr !== '' || this.numberExist1  || this.emailExist) {
        isValid = false
      }
    if (isValid) {
       MyJobApp.addUpdateLead(
       this.leadDetail,
        response => {
          this.$root.$emit('addleadHandler', true)
          document.body.style = 'overflow: visible;'
          this.$store.dispatch('SetAlert', {showAlert: true, message: response.message, color: 'success'})
          this.$store.dispatch('SetLoader', {status: false, message: ''})
        },
        (error) => {
          this.$store.dispatch('SetAlert', {showAlert: true, message: error.message, color: 'error'})
          this.$store.dispatch('SetLoader', {status: false, message: ''})
        }
      )
    }
  },
  discard () {
    document.body.style = 'overflow: visible;'
    this.$root.$emit('addleadHandler', false)
  },
  checkContactNumber (from) {
      if (from === 'prime') {
        let value = checkSpeialChar(this.leadDetail.phoneNumber)
          console.log('value', value)
          if (value) {
            this.errObj.primNumErr = 'Please enter valid contact number'
          } else {
            this.errObj.primNumErr = ''
          }
          if (this.leadDetail.phoneNumber !== '' ) {
              this.checkExist('number', this.leadDetail.phoneNumber, from)
          }
      }
  },
  checkEmail () {
    if (this.leadDetail.emailAddress !== '' && validateEmail(this.leadDetail.emailAddress) === false) {
        this.errObj.emailErr = "Please enter valid email";
        return;
    } else {
      this.errObj.emailErr = "";
      if (this.leadDetail.emailAddress !== '') {
          this.checkExist('email', this.leadDetail.emailAddress, 'email')
        }
      // this.verifycustomerDataExist('customer_email', this.leadDetail.emailAddress)
    }
  },
  checkExist (from, value, contact) {
      MyJobApp.getLeadCustEmailNumCheck(
        from,
        value,
        0,
        response => {
          if (from === 'number' && response.Data && contact === 'prime') {
            this.numberExist1 = true
            this.existArr1 = response.Data
          } else if (from === 'email' && response.Data && contact === 'email') {
            this.emailExist = true
            this.existArr3 = response.Data
          }
        },
        error => {
          this.$store.dispatch('SetLoader', {status: false, message: ''})
          this.$store.dispatch('SetAlert', {showAlert: true, message: error.message, color: 'error'})
        }
      ) 
    },
    routingTodata (value) {
      let url = ''
      if (value.isLead) {
        url = this.$router.resolve({name: 'LeadDetail', params: {leadId: value.customerId}})
      } else {
        url = this.$router.resolve({name: 'customerDetails', params: {customerId: value.customerId}})
      }
      window.open(url.href, '_blank')
    },
},
beforeDestroy () {
  // this.$root.$off('closeProfileDialog')
}
}
</script>
<style scoped>

</style>